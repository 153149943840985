.fullscreen-overlay {
  position: absolute;
  top: 0;
  z-index: 100;
  background-color: #000000ba;
}

.fullscreen-overlay svg {
  fill: rgb(91, 95, 199);
}
