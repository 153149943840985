.allow-mic-image {
  border-radius: 0.5rem;
}

.user-exclamation{
    background-color: #9970d350;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.important-exclamation {
  color: rgb(255,0,0);
}

.user-exclamation__text{
  font-style: italic;
}
