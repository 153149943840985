.start-captions {
  color: rgb(127, 113, 218);
}

.stop-captions {
  color: rgb(255, 0, 0);
}

.disabled {
  opacity: 0.2;
}

.button-container {
  position: relative;
}

.caption-button-loader {
  position: absolute;
  padding: 4px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
