.captioner-interface {
  position: relative;
  padding: 1rem;
}

.captioner-interface {
  padding: 1rem;
}

.divider {
  opacity: 0.2;
}

.w-100 {
  width: 100%;
}

.captioner-status-layout {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.call-end-icon svg {
  fill: rgb(196, 49, 75);
}
