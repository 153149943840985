.counter-text {
  font-size: 4rem !important;
}

.blinking {
  animation: blink-animation 1.5s infinite;
}

.count-down{
  color: rgb(196, 49, 75);
}

/* 
* creates a blinking effect by changing the 
* opacity of the element from 1 to 0 and back 
*/
@keyframes blink-animation {
  0%,
  50% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
